export function calculatePixelsHeight(
  ratioWidth,
  ratioHeight,
  pixelsWidth,
) {
  return pixelsWidth / (ratioWidth / ratioHeight)
}

export function calculatePixelsWidth(
  ratioWidth,
  ratioHeight,
  pixelsHeight,
) {
  return pixelsHeight * (ratioWidth / ratioHeight)
}
