import $ from 'jquery'
import AspectRatioInputsObserver from './aspect-ratio-inputs-observer';

(new AspectRatioInputsObserver(
  $('.js-input-ratio-width'),
  $('.js-input-ratio-height'),
  $('.js-input-pixels-width'),
  $('.js-input-pixels-height'),
  $('.js-preview'),
  $('.js-calculator').height() || 270,
));
