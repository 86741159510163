import autoBind from "auto-bind";
import {
  calculatePixelsHeight,
  calculatePixelsWidth,
} from "./aspect-ratio-calculator";

class AspectRatioInputsObserver {
  constructor(
    $ratioWidth,
    $ratioHeight,
    $pixelsWidth,
    $pixelsHeight,
    $preview,
    previewMaxHeight
  ) {
    this.$ratioWidth = $ratioWidth;
    this.$ratioHeight = $ratioHeight;
    this.$pixelsWidth = $pixelsWidth;
    this.$pixelsHeight = $pixelsHeight;
    this.$preview = $preview;
    this.previewMaxHeight = previewMaxHeight;
    this.$root = document.documentElement;

    autoBind(this);
    this.applyListeners();
  }

  applyListeners() {
    this.$pixelsWidth.on("keyup", this.updatePixelsHeight);
    this.$ratioWidth.on("keyup", this.updatePixelsHeight);
    this.$ratioHeight.on("keyup", this.updatePixelsHeight);
    this.$pixelsHeight.on("keyup", this.updatePixelsWidth);
  }

  updatePixelsHeight() {
    const ratioWidth = parseInt(this.$ratioWidth.val(), 10);
    const ratioHeight = parseInt(this.$ratioHeight.val(), 10);
    const pixelsWidth = parseInt(this.$pixelsWidth.val(), 10);
    const pixelsHeight = calculatePixelsHeight(
      ratioWidth,
      ratioHeight,
      pixelsWidth
    );

    this.$pixelsHeight.val(pixelsHeight);

    this.updatePreview(ratioWidth, ratioHeight, pixelsWidth, pixelsHeight);
  }

  updatePixelsWidth() {
    const ratioWidth = parseInt(this.$ratioWidth.val(), 10);
    const ratioHeight = parseInt(this.$ratioHeight.val(), 10);
    const pixelsHeight = parseInt(this.$pixelsHeight.val(), 10);
    const pixelsWidth = calculatePixelsWidth(
      ratioWidth,
      ratioHeight,
      pixelsHeight
    );

    this.$pixelsWidth.val(pixelsWidth);

    this.updatePreview(ratioWidth, ratioHeight, pixelsWidth, pixelsHeight);
  }

  updatePreview(ratioWidth, ratioHeight, pixelsWidth, pixelsHeight) {
    this.$preview
      .attr("data-ratio-width", ratioWidth)
      .attr("data-ratio-height", ratioHeight)
      .attr("data-pixels-width", pixelsWidth)
      .attr("data-pixels-height", pixelsHeight);

    const maxHeight = this.previewMaxHeight - 50;
    const maxWidth = this.$preview.parent().width() - 150;
    const smallDimension = maxWidth > maxHeight ? maxHeight : maxWidth;
    const orientation = ratioWidth > ratioHeight ? "landscape" : "portrait";

    let width = (smallDimension * ratioWidth) / ratioHeight;
    let height = smallDimension;

    if (orientation === "landscape") {
      width = smallDimension;
      height = (smallDimension * ratioHeight) / ratioWidth;
    }

    this.$root.style.setProperty("--preview-width", width + "px");
    this.$root.style.setProperty("--preview-height", height + "px");
  }
}

export default AspectRatioInputsObserver;
